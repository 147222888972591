<template>
   <div>
        <div v-if="loading">
           <Loading />
       </div>
       <div v-else>
            <div class="license-body" style="margin: auto; padding: 1rem; width: 850px; height: auto; background-color: #fff;">
            <div class="text-right">
                <b-button variant="primary" @click="pdfExport" class="btn btn-sm mb-2">
                <i class="ri-printer-line"></i> {{ $t('globalTrans.print') }}
                </b-button>
            </div>
            <div style="padding: 3px; border: 1px solid #444444;">
                <div style="padding: 3px; border: 2px solid #444444;">
                    <div style="padding: 1.5rem; border: 1px solid #444444;">
                        <!-- license- header -->
                        <div style="text-align: center;">
                            <img style="max-width: 120px; margin-bottom: .5rem;"
                                src="@/assets/images/logo.png">
                            <p style="font-weight: 600; font-size: 16px;">{{ $t('globalTrans.government_republic_of_bangladesh') }}</p>
                            <p style="font-weight: 600; font-size: 16px; margin-bottom: .5rem;">{{ $t('teaGardenService.bangladesh_tea_board') }}</p>
                            <p style="">{{ $t('teaGardenService.tea_board_address') }}</p>
                            <!-- <p style="">{{ $t('teaGardenService.tea_board_address_2') }}</p> -->
                            <p style="font-weight: 600;">{{ $t('teaGardenService.online_tea_license_certificate') }}</p>
                            <p style="font-weight: 600; text-decoration: underline; margin-top: 1rem;">{{ $t('teaGardenPanel.license_number')}} :
                                {{ appDetail.application?.registration_no }}</p>
                        </div>
                        <!-- license body -->
                        <div style="margin-top: 3rem; text-align: center;">
                            <p>
                                {{ $t('teaGardenService.title_first_part')}} <strong> {{ currentLocale === 'en' ? appDetail.company_name_en : appDetail.company_name_bn }} </strong> {{ $t('teaGardenService.title_second_part')}} <strong>{{ currentLocale === 'en' ? appDetail.applicant_name_en : appDetail.applicant_name_bn }}</strong>
                                {{ $t('teaGardenService.title_third_part_blender')}}
                            </p>
                            <p style="font-weight: 600; margin: 2rem 0;">{{ $t('teaGardenService.license_desc') }}:</p>
                            <ol
                            :style="getLocaleStyle" style="list-style-position: inside; text-align: left; margin-left: 1.5rem;">
                                <li> {{ $t('teaGardenService.license_company')}} : <strong>{{ currentLocale === 'en' ? appDetail.company_name_en : appDetail.company_name_bn }}</strong></li>
                                <li> {{ $t('teaGardenPanel.company_address')}} : <strong> {{ getCompanyAddress(appDetail) }} </strong></li>
                                <li>{{ $t('teaGardenService.owner_md_name')}} : <strong>{{ currentLocale === 'en' ? appDetail.applicant_name_en : appDetail.applicant_name_bn }}</strong></li>
                                <li>{{ $t('teaGardenPanel.applicant_address')}} : <strong>{{ this.getApplicantAddress(appDetail) }} </strong></li>
                                <li> {{ $t('teaGardenService.nid_num')}} : <strong>{{ $n(appDetail.nid, { useGrouping: false }) }}</strong></li>
                                <li>{{ $t('teaGardenService.license_issue')}} : <strong> {{ appDetail.application.approved_date === null ? '' : appDetail.application.approved_date | dateFormat }} </strong></li>
                            </ol>
                             <p style="font-weight: 600; margin-top: 1rem;">{{ $t('externalLrcpn.expire_date')}} : {{ formatted(appDetail.application.expired_date) }}</p>
                        </div>
                        <!-- license footer -->
                        <table style="margin-top: 3rem;">
                            <tr>
                                <td style="width: 33.33%;">
                                    <div v-if="appDetail.application.service_instruction">
                                        <strong>{{ $t('teaGardenPanel.conditions') }}:</strong>
                                            <p v-html="currentLocale === 'en' ? appDetail.application.service_instruction.certificate_instructions_en : appDetail.application.service_instruction.certificate_instructions_bn" style="list-style-type: bengali; list-style-position: inside; font-size: 11px;"></p>
                                    </div>
                                </td>
                               <td style="width: 25%; text-align: center; padding-right: 10px; padding-left: 10px; vertical-align: top;">
                                    <div>
                                        <vue-qrcode :value="getQrCodeContent()" :width="150"/>
                                    </div>
                                </td>
                                <td style="width: 30%; margin-left: 5px; padding-left: 5px; font-size: 15px; vertical-align: top;">
                                    <div>
                                        {{ $t('teaGardenService.certificate_signature_heading') }}, <br><br>
                                        <img v-if="appDetail.application.document_signatory && signatory_user_info.signature && appDetail.application.document_signatory.show_signature" :src="authServiceBaseUrl + 'storage/' + signatory_user_info.signature" width="100px" alt="">
                                        <img v-else-if="approve_user_info.signature" :src="authServiceBaseUrl + 'storage/' + approve_user_info.signature" width="100px" alt="">
                                        <p v-if="appDetail.application.document_signatory || appDetail.application.approver_name_en">{{ $t('globalTrans.name') }}:
                                            <span v-if="appDetail.application.document_signatory && appDetail.application.document_signatory.name_en && appDetail.application.document_signatory.show_name" style="text-decoration-style: dotted; text-decoration-line: underline;">{{ currentLocale === 'en' ? signatory_user_info.text_en : signatory_user_info.text_bn }}</span>
                                            <span v-else style="text-decoration-style: dotted; text-decoration-line: underline;">{{ currentLocale === 'en' ? approve_user_info.text_en : approve_user_info.text_bn }}</span>
                                        </p>
                                        <p v-if="appDetail.application.document_signatory || appDetail.application.approver_designation_en">{{ $t('globalTrans.designation') }}:
                                            <span v-if="appDetail.application.document_signatory && appDetail.application.document_signatory.designation_en && appDetail.application.document_signatory.show_name" style="text-decoration-style: dotted; text-decoration-line: underline;">{{ currentLocale === 'en' ? signatory_user_info.designation_en : signatory_user_info.designation_bn }}</span>
                                            <span v-else style="text-decoration-style: dotted; text-decoration-line: underline;">{{ currentLocale === 'en' ? approve_user_info.designation_en : approve_user_info.designation_bn }}</span>
                                        </p>
                                        <p v-if="appDetail.application.document_signatory && appDetail.application.document_signatory.email">{{ $t('globalTrans.email') }}:
                                            <span v-if="appDetail.application.document_signatory.show_name" style="text-decoration-style: dotted; text-decoration-line: underline;">{{ signatory_user_info.email }}</span>
                                            <span v-else style="text-decoration-style: dotted; text-decoration-line: underline;">{{ approve_user_info.email }}</span>
                                        </p>
                                        <p v-if="appDetail.application.document_signatory && appDetail.application.document_signatory.mobile">{{ $t('globalTrans.mobile') }}:
                                            <span v-if="appDetail.application.document_signatory.show_name" style="text-decoration-style: dotted; text-decoration-line: underline;"><span v-if="signatory_user_info.mobile">{{ EngBangNum(signatory_user_info.mobile) }}</span></span>
                                            <span v-else style="text-decoration-style: dotted; text-decoration-line: underline;"><span v-if="approve_user_info.mobile">{{ EngBangNum(approve_user_info.mobile) }}</span></span>
                                        </p>
                                        <p>{{ $t('globalTrans.date') }} <span
                                                style="text-decoration-style: dotted; text-decoration-line: underline;">{{ appDetail.application.approved_date | dateFormat }}</span>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            </div>
       </div>
    </div>
  </template>
  <script>
  import RestApi, { teaGardenServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
  import VueQrcode from 'vue-qrcode'
  import { dateFormat } from '@/utils/fliter'
  import Loading from './loading/Details.vue'
  import { AuthUserInfo } from '../../../api/routes'
  export default {
    props: ['items'],
    components: {
        VueQrcode,
        Loading
    },
    created () {
        this.getAppDetail()
    },
    data () {
        return {
            authServiceBaseUrl: authServiceBaseUrl,
            appDetail: {},
            loading: false,
            approve_user_info: {},
            signatory_user_info: {}
        }
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        },
        appId () {
            return this.$route.params.id
        },
        getLocaleStyle () {
            if (this.currentLocale === 'bn') {
                return { listStyleType: 'bengali' }
            } else {
                return { listStyleType: 'english' }
            }
        }
    },
    methods: {
        EngBangNum (n) {
            if (this.$i18n.locale === 'bn') {
                return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
            } else {
                return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
            }
        },
         getDistrictName (id) {
        const district = this.$store.state.CommonService.commonObj.districtList.find(item => item.value === id)
            if (district && this.$i18n.locale === 'bn') {
                return district.text_bn
            } else if (district && this.$i18n.locale === 'en') {
                return district.text_en
            }
        },
        getCityCorporationName (id) {
        const cityCorporation = this.$store.state.CommonService.commonObj.cityCorporationList.find(item => item.value === id)
            if (cityCorporation && this.$i18n.locale === 'bn') {
                return cityCorporation.text_bn
            } else if (cityCorporation && this.$i18n.locale === 'en') {
                return cityCorporation.text_en
            }
        },
        getUpzillaName (id) {
        const obj = this.$store.state.CommonService.commonObj.upazilaList.find(item => item.value === id)
            return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        },
        getPauroshobaName (id) {
        const municipality = this.$store.state.CommonService.commonObj.municipalityList.find(item => item.value === id)
            if (municipality && this.$i18n.locale === 'bn') {
                return municipality.text_bn
            } else if (municipality && this.$i18n.locale === 'en') {
                return municipality.text_en
            }
        },
        getServiceName (serviceId) {
          const ServiceName = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === serviceId)
          return this.$i18n.locale === 'en' ? ServiceName !== undefined ? ServiceName.text_en : '' : ServiceName !== undefined ? ServiceName.text_bn : ''
        },
        getQrCodeContent () {
            let content
            if (this.currentLocale === 'en') {
            content = this.$t('teaGardenPanel.license_name') + ' : ' + this.$t('teaGardenService.service_blender') + '\n' + this.$t('teaGardenPanel.license_number') + ' : ' + this.appDetail.application?.registration_no + '\n' + this.$t('teaGardenService.license_issue') + ' : ' + this.appDetail.application.approved_date + '\n' + this.$t('externalLrcpn.expire_date') + ' : ' + this.appDetail.application.expired_date
            } else {
            content = this.$t('teaGardenPanel.license_name') + ' : ' + this.$t('teaGardenService.service_blender') + '\n' + this.$t('teaGardenPanel.license_number') + ' : ' + this.$n(this.appDetail.application?.registration_no, { useGrouping: false }) + '\n' + this.$t('teaGardenService.license_issue') + ' : ' + dateFormat(this.appDetail.application.approved_date) + '\n' + this.$t('externalLrcpn.expire_date') + ' : ' + dateFormat(this.appDetail.application.expired_date)
            }
            return content
        },
        back () {
            this.$router.go(-1)
        },
        async getAppDetail () {
            this.loading = true
            const result = await RestApi.getData(teaGardenServiceBaseUrl, '/btb/license-application/blender-show' + '/' + this.appId)
            if (result.success) {
                this.appDetail = result.data
                if (this.appDetail.application.document_signatory && this.appDetail.application.document_signatory.user_id) {
                    this.getSignatoryUserInfo(this.appDetail.application.document_signatory.user_id)
                }
                if (this.appDetail.application.approver_id) {
                    this.getApproveUserInfo(this.appDetail.application.approver_id)
                }
            }
            this.loading = false
        },
        async pdfExport () {
            this.loading = true
            const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5, app_id: this.appId, show_certificate: true })
            const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 31)
            if (service !== undefined) {
                if (service.office_type_id) {
                params.office_type_id = service.office_type_id
                }
                if (service.office_id) {
                params.office_id = service.office_id
                }
            }
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, '/btb/license-application/blender-view-pdf', params)
            var blob = new Blob([result], {
                type: 'application/pdf'
            })
            this.loading = false
            var url = window.URL.createObjectURL(blob)
            window.open(url).print()
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        },
          async getSignatoryUserInfo (id) {
            const result = await RestApi.getData(authServiceBaseUrl, AuthUserInfo + id)
            if (result.success) {
                this.signatory_user_info = result.data
                if (this.signatory_user_info) {
                    this.getDesignationNameSignatory(this.signatory_user_info.designation_id)
                    this.getOfficeNameSignatory(this.signatory_user_info.office_id)
                }
            }
        },
        async getApproveUserInfo (id) {
            const result = await RestApi.getData(authServiceBaseUrl, AuthUserInfo + id)
            if (result.success) {
                this.approve_user_info = result.data
                if (this.approve_user_info) {
                    this.getDesignationNameApprove(this.approve_user_info.designation_id)
                    this.getOfficeNameApprove(this.approve_user_info.office_id)
                }
            }
        },
        getDesignationNameSignatory (id) {
            const obj = this.$store.state.CommonService.commonObj.designationList.find(item => item.value === parseInt(id))
            if (obj) {
                this.signatory_user_info.designation_en = obj.text_en
                this.signatory_user_info.designation_bn = obj.text_bn
            }
            return true
        },
        getOfficeNameSignatory (id) {
            const obj = this.$store.state.CommonService.commonObj.officeList.find(item => item.value === parseInt(id))
            if (obj) {
                this.signatory_user_info.office_en = obj.text_en
                this.signatory_user_info.office_bn = obj.text_bn
            }
            return true
        },
        getDesignationNameApprove (id) {
            const obj = this.$store.state.CommonService.commonObj.designationList.find(item => item.value === parseInt(id))
            if (obj) {
                this.approve_user_info.designation_en = obj.text_en
                this.approve_user_info.designation_bn = obj.text_bn
            }
            return true
        },
        getOfficeNameApprove (id) {
            const obj = this.$store.state.CommonService.commonObj.officeList.find(item => item.value === parseInt(id))
            if (obj) {
                this.approve_user_info.office_en = obj.text_en
                this.approve_user_info.office_bn = obj.text_bn
            }
            return true
        },
        formatted (value) {
            return new Date(value).toLocaleString(this.$i18n.locale + '-GB', {
            day: 'numeric', // numeric, 2-digit
            year: 'numeric', // numeric, 2-digit
            month: 'long' // numeric, 2-digit, long, short, narrow
            })
        },
        getUnionName (id) {
        const obj = this.$store.state.CommonService.commonObj.unionList.find(item => item.value === id)
            return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        },
        getCompanyAddress (data) {
            var address = ''
            address += this.currentLocale === 'en' ? data.c_address_en : data.c_address_bn

            // when area type wise address set
            if (data.c_area_type_id === 1) {
                if (data.c_union_id) {
                    address += ', ' + this.getUnionName(data.c_union_id)
                }

                if (data.c_pauroshoba_id) {
                    address += ', ' + this.getPauroshobaName(data.c_pauroshoba_id)
                }

                if (data.c_upazila_id) {
                    address += ', ' + this.getUpzillaName(data.c_upazila_id)
                }
            } else if (data.c_area_type_id === 2) {
                if (data.c_union_id) {
                    address += ', ' + this.getUnionName(data.c_union_id)
                }

                if (data.c_city_corporation_id) {
                    address += ', ' + this.getCityCorporationName(data.c_city_corporation_id)
                }
            } else if (data.c_area_type_id === 3) {
                if (data.c_pauroshoba_id) {
                    address += ', ' + this.getPauroshobaName(data.c_pauroshoba_id)
                }

                if (data.c_city_corporation_id) {
                    address += ', ' + this.getCityCorporationName(data.c_city_corporation_id)
                }
            }

            if (data.c_district_id) {
                address += ', ' + this.getDistrictName(data.c_district_id)
            }

            return address
        },
        getApplicantAddress (data) {
            var address = ''
            address += this.currentLocale === 'en' ? data.address_en : data.address_bn

            // when area type wise address set
            if (data.area_type_id === 1) {
                if (data.union_id) {
                    address += ', ' + this.getUnionName(data.union_id)
                }

                if (data.pauroshoba_id) {
                    address += ', ' + this.getPauroshobaName(data.pauroshoba_id)
                }

                if (data.upazila_id) {
                    address += ', ' + this.getUpzillaName(data.upazila_id)
                }
            } else if (data.area_type_id === 2) {
                if (data.union_id) {
                    address += ', ' + this.getUnionName(data.union_id)
                }

                if (data.city_corporation_id) {
                    address += ', ' + this.getCityCorporationName(data.city_corporation_id)
                }
            } else if (data.area_type_id === 3) {
                if (data.pauroshoba_id) {
                    address += ', ' + this.getPauroshobaName(data.pauroshoba_id)
                }

                if (data.city_corporation_id) {
                    address += ', ' + this.getCityCorporationName(data.city_corporation_id)
                }
            }

            if (data.district_id) {
                address += ', ' + this.getDistrictName(data.district_id)
            }

            return address
        }
    }
}
</script>
<style>
    .license-body {
        background-image: url('../../../../../../assets/images/btb-watermark.png');
        background-size: initial;
        background-position: center;
        background-repeat: no-repeat;
    }
</style>
